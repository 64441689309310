<template>
  <div>
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>


    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">

      <div style="background:#f4f5f7;">
        <div style="display:flex;flex-direction:row; margin: 0 auto; width: 1170px;">
          <div style="display:flex;flex-direction:column; align-items:flex-end;  background-color:#ffffff;width: 285px; ">
            <div style="display:flex;flex-direction:column;  margin-top:42px; ">
              <div
                v-for="(item,index) in leftBarItemList"
                :key="index"
                :class="item.title === selectedRow ? 'selected-category-row selected-category-item' : 'category-item'"
                style="padding-top:10px;padding-bottom:10px;margin-bottom:10px; cursor:pointer;"
                @click="itemClick(item.title)"
              >{{item.title}}</div>
            </div>
          </div>


          <div v-if="!pageLoaded" style="padding-top:130px; height: 280px; width: 100%;">
            <div class="loader" style="text-align: center; margin: 0 auto;"></div>
          </div>
          <div v-else style="width: 100%; padding-left:60px;  background-color: #f4f5f7;padding-bottom:70px; min-height:300px;">
            <HelpNavBar
              :contentData="navBarData"
              :showButton="true"
              style=" margin-top:45px;"
            />



            <div class="ticket-area">
              <p class="title">{{ticket.title}}</p>


              <div class="ticket-message-area">
                <div v-for="ticketMessage in ticket.messages" class="ticket-message" :style="ticketMessage.sender !== 'admin' ? ' justify-content:flex-end; ' : 'justify-content:flex-end; flex-direction:row-reverse'">
                  <div :class="ticketMessage.sender !== 'admin' ? 'bubble-left' : 'bubble-bi'">
                    <div style="white-space: pre-line">

                        <span  v-html="ticketMessage.message"></span>
                        <span v-for="upload in ticketMessage.uploads" style="display: block; margin-top: 5px; margin-bottom: 8px; display: flex;">
                        <img style="height: 27px; width: 21px; " :src="upload.icon_png">
                        <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
                      </span>
                      <p v-if="ticketMessage.asset">
                        <video v-if="ticketMessage.asset.type === 'video'" class="mt-2" @play="sendPlayEvent(ticketMessage.asset.id)" width="80%" :poster="ticketMessage.asset.info.thumbnailUrl" controls>
                          <source :src="ticketMessage.asset.info.videoUrl" type="video/mp4">
                          Tarayacınız video oynatımını desteklemiyor.
                        </video>
                        <a v-else :href="ticketMessage.asset.info.imageUrl" target="_blank" @click="sendPlayEvent(ticketMessage.asset.id)">
                          <img class="mt-2" :src="ticketMessage.asset.info.imageUrl" width="80%" />
                        </a>
                      </p>
                    </div>

                    <p v-if="ticketMessage.sender !== 'admin'">
                      {{ user.username }}, {{ ticketMessage.created_at | customDateFormat('dd MMMM, yyyy - HH:mm') }}
                    </p>
                    <p v-else style="white-space: normal">
                      Bionluk, {{ ticketMessage.created_at | customDateFormat('dd MMMM, yyyy - HH:mm') }}
                    </p>

                  </div>
                  <div style="align-self: flex-end; margin-bottom: -6px;" :style="ticketMessage.sender !== 'admin' ? 'margin-left:5px;' : 'margin-right:5px; '">
                    <img class="avatar" :src="ticketMessage.sender !== 'admin' ? user.avatar_url : 'https://gcdn.bionluk.com/site/cicons/bi_avatar.png'">
                  </div>
                </div>
              </div>

              <div class="rate-us-box" v-if="ticket.showRateUsBox">
                <p class="text">Sana yardımcı olabildiğimizi düşünüyor musun?</p>
                <div style="display: flex; width: 190px; justify-content: space-between; margin-top: 8px;">
                  <div class="img-area">
                    <img @click="rateUs(2)" :class="rateUsScore === 2 ? 'img-active' : 'img'" src="https://gcdn.bionluk.com/site/cicons/emojis/2_.png">
                  </div>
                  <div class="img-area">
                    <img @click="rateUs(3)" :class="rateUsScore === 3 ? 'img-active' : 'img'" src="https://gcdn.bionluk.com/site/cicons/emojis/3_.png">
                  </div>
                  <div class="img-area">
                    <img @click="rateUs(4)" :class="rateUsScore === 4 ? 'img-active' : 'img'" src="https://gcdn.bionluk.com/site/cicons/emojis/4_.png">
                  </div>
                </div>
                <textarea v-model="rateUsComment" v-if="rateUsScore === 2" placeholder="Bu puanı vermendeki en önemli neden neydi? Kendimizi geliştirebilmemiz için geri bildirimin çok önemli! Mesajın varsa bu alana yazabilirsin. " style="height: 75px; width: 350px; margin-top: 20px; margin-bottom: 10px;"></textarea>
                <button :disabled="buttonLoading" @click="sendRateUs" v-if="rateUsScore" class="cuper-white-button" style="width: 80px; padding: 5px 10px; margin-top: 20px; margin-bottom: 20px;">Gönder</button>
              </div>


              <div style="position: relative">
                <div :class="{'emoji-img': true, 'emoji-img-active': openEmojiPicker}" @click="openEmojiPicker = !openEmojiPicker"></div>
                <emoji-picker class="emoji-picker"
                              v-click-outside="pageClickHandler"
                              v-if="openEmojiPicker"
                              :emojiSize="32"
                              :emojisByRow="7"
                              :showSearch="true"
                              :i18n="{ search: 'Ara...'}"
                              @select="addEmoji"/>
              </div>



              <textarea maxlength="600" id="comment-input" v-model="message" class="c-text-area" style="width: 793px;height: 154px;" placeholder="Mesajını buraya yazabilirsin...">

              </textarea>



              <div style="display: flex; justify-content: space-between; width: 843px; margin-top: 15px; margin-bottom: 15px;">
                <div style="text-align: left">


                  <div style="display: flex; align-items: center; height: 18px; margin-bottom: 20px;">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                    <p @click="chooseFiles('order_support_upload')" class="start-upload-text">Dosya Ekle</p>
                    <input id="order_support_upload" style="visibility: hidden" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.TICKET)">
                  </div>

                  <div v-for="file in supportFiles" style="margin-bottom: 15px;">
                    <div style="display: flex; align-items: center">
                      <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                        <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                      </div>
                      <div v-else @click="deleteFile(file.externalID, 'ticket')">
                        <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                      </div>

                      <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                      <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                      <div style="display: flex; flex-direction: column; margin-left: 5px;">
                        <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                        <progress v-if="$store.state.percentCompleted.ticket[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.ticket[file.stamp]' max="100"></progress>
                      </div>
                    </div>
                  </div>

                </div>

                <div style="color: #8b95a1; font-size: 13px; font-weight: 500">
                  {{message.length}} / <span style="color: #2d3640">600</span>
                </div>
              </div>

              <div style="display: flex; flex-direction: row-reverse;  width: 843px; margin-bottom: 50px;">
                <button @click="sendTicketMessage" :disabled="buttonLoading || !message.length" class="cuper-green-button">Yeni Mesaj Gönder</button>
              </div>



            </div>


          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HelpNavBar from "../../../components/HelpNavBar";
  import Accordion from "../../../components/Accordion";
  import HelpHeaderAndDescription from "../../../components/HelpHeaderAndDescription";
  import { VEmojiPicker } from "v-emoji-picker-bi";
  export default {
    name: "src-pages-body-tickets-ticketList-v2",
    components: {
      HelpNavBar,
      Accordion,
      HelpHeaderAndDescription,
      emojiPicker: VEmojiPicker,
    },
    data() {
      return {
        uuid:null,
        buttonLoading:false,
        pageLoaded: true,
        ticket:{
          'title': null,
          'messages': null,
        },


        supportFiles:[],
        rateUsScore:null,
        rateUsComment:'',



        leftBarItemList: [
          {
            title: "Tüm Destek Taleplerin"
          },
          {
            title: "Yeni Destek Talebi Oluştur"
          }
        ],
        selectedRow: "Tüm Destek Taleplerin",
        message: '',

        openEmojiPicker: false,
      };
    },

    methods: {
      sendPlayEvent(id) {
        this.Helper.trackEvents.customEvent("custom_video_view", {
            title: null,
            id,
            impression_id:this.ticket.id,
            page: this.$store.state.componentMap.page
          });
      },
      sendRateUs(){
        this.buttonLoading = true;
        this.api.general.rateUs(this.uuid, this.rateUsScore, this.rateUsComment, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.buttonLoading = false;
            if (result.success) {
              this.rateUsScore = null;
              this.rateUsComment = '';
              this.$toasted.global.infoToast({description: 'Geri bildirimin için çok teşekkürler!'});
              this.ticket.showRateUsBox = false;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false;
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      rateUs(score){
        //üzgün surat: 2, normal surat:3, gulen surat:4 gelir
        if(this.buttonLoading){
          return false;
        }
        this.rateUsScore = score;
      },


      addEmoji(emoji) {
        this.message = this.message + " " + emoji.data;
        const ele = document.getElementById('comment-input');
        ele.focus();
      },

      pageClickHandler(event) {
        this.openEmojiPicker = false;
      },

      sendTicketMessage(){
        let uploadIDs = this.supportFiles.map(function (file) {
          return file.externalID;
        });
        this.buttonLoading = true;
        this.api.general.addMessageToTicket(this.uuid, this.message, uploadIDs, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.disableButton = false;
            if (result.success) {
              this.ticket.messages.push(result.data);
              this.message = "";
              this.supportFiles = [];
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false;
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });

      },
      chooseFiles(id) {
        document.getElementById(id).click()
      },

      cancelUpload(timeStamp){
        this.cancelUploadRequest(parseInt(timeStamp));

        let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
        if(supportFileIndex !== -1){
          this.supportFiles.splice(supportFileIndex, 1);
        }
      },
      deleteFile(uploadID, type) {
        this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              if (type === this.Constants.UPLOAD_TYPES.TICKET) {
                this.supportFiles = this.supportFiles.filter(function (file) {
                  return file.externalID !== uploadID
                });
              } else {
                this.files = this.files.filter(function (file) {
                  return file.externalID !== uploadID
                });
              }

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      onFileChange(e, type) {

        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.buttonLoading = true;

        let timeStamp = +new Date();


        let rand = Math.floor(Math.random() * 1000) + 1;
        timeStamp = timeStamp + rand;
        this.supportFiles.push({
          url: null,
          externalID: timeStamp,
          filename: files[0].name,
          stamp: timeStamp
        });

        this.uploadFileToGoogle(files[0], type, null, timeStamp)
          .then(result => {
            let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
            let supportFile = this.supportFiles[supportFileIndex];
            supportFile.externalID = result.externalID;
            supportFile.url = this.Constants.CDN_UPLOADS_G + result.uploadName;
            supportFile.icon_png = result.icon_png;
            this.supportFiles[supportFileIndex] = supportFile;


            this.buttonLoading = false;

          }).catch(err => {
          this.buttonLoading = false;

        });


        e.target.value = ''

      },


      cancelClick: function() {
        if(this.selectedRow === 'Yeni Destek Talebi Oluştur') {
          if(this.currentStep === 1){
            this.selectedRow = 'Tüm Destek Taleplerin';
            this.currentStep = 1;
            this.message = "";
            this.progressPercent = 0;
            this.selectedTicketCategoryId = null;
            window.scrollTo(0,0);
          } else {
            this.currentStep --;
            window.scrollTo(0,0);
          }

        }
      },
      itemClick: function(title) {
        this.selectedRow = title;
        if (title === "Tüm Destek Taleplerin") {
          this.$router.push('/tickets');
        } else if(title === 'Yeni Destek Talebi Oluştur'){
          this.$router.push('/tickets?tab=new');
        }
      },

    },

    computed: {

      navBarData() {

        return {
          navBarList: [
            {
              title: "Destek",
              path: "/destek"
            },
            {
              title: "Destek Talepleri",
              path: "/tickets"
            },
            {
              title: "Tüm Destek Taleplerin",
              path: "/tickets"
            }
          ],
          buttonTitle: "Yardım Merkezine Dön",
          buttonPath: "/destek"
        };
      }
    },
    watch:{

      "$store.state.routerParams": function (newValue, oldValue) {
        if(newValue && newValue[0]){
          this.uuid = newValue[0];
          this.api.general.getTicket(this.uuid)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.ticket = result.data.ticket;

                this.$store.state.indexHTMLTitle = result.data.meta.title;
                this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

                this.Helper.trackEvents.pageView(this.Helper.ticketDetailPageProps(result.data, this.$store.state.routerParams[0]));
              } else {
                this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },
      "$store.state.temp_icon_png": function (newValue, oldValue) {
        this.supportFiles.forEach(function (file) {
          if(newValue[file.stamp]){
            file.icon_png = newValue[file.stamp];
          }
        });
      },
    },

    created() {

      this.uuid = this.$store.state.routerParams[0];
      this.api.general.getTicket(this.uuid)
        .then(({data}) => {
          let result = data;
          if (result.success) {
            this.ticket = result.data.ticket;

            this.$store.state.indexHTMLTitle = result.data.meta.title;
            this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

            this.Helper.trackEvents.pageView(this.Helper.ticketDetailPageProps(result.data, this.$store.state.routerParams[0]));
          } else {
            this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    }
  };
</script>

<style scoped lang="scss">

  .rate-us-box{
    width: 843px;
    min-height: 120px;
    border-radius: 10px;
    margin-top: 60px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #34516f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text{
      font-size: 16px;
      line-height: 1.75;
      color: #ffffff;
    }

    .img-area{
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .img{
        transition: all .1s ease-in-out;
        cursor: pointer;
        width: 30px;
        height: 30px;
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
        &:hover{
          width: 40px;
          height: 40px;
          -webkit-filter: grayscale(0);
          filter: none;
        }
      }
      .img-active{
        width: 40px;
        height: 40px;
        -webkit-filter: grayscale(0);
        filter: none;
      }
    }

  }

  .emoji-img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 60px;
    top: 170px;
    cursor: pointer;
    z-index: 2;
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon.svg');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;

    &:hover {
      background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg');
      width: 22px;
      height: 22px;
      right: 59px;
      top: 169px;
    }
  }

  .emoji-img-active {
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg') !important;
  }

  .emoji-picker {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    position: absolute;
    right: 60px;
    top: -122px;
    z-index: 3;
  }

  .ticket-area{
    margin-top: 40px;
    .title{
      font-size: 46px;
      font-weight: bold;
      color: #2d3640;
    }


    .ticket-message-area{



      margin-bottom: 100px;
      .avatar{
        width: 43px;
        height: 43px;
        border-radius: 50%;
        border: 1px solid #eff3f8;
      }



      .ticket-message{
        margin-top: 50px;
        display: flex;
        width: 100%;

      }




      .bubble-right{
        div{


          line-height: 1.86;
          color: #2d3740;
          width: 638px;
          padding: 15px 23px;
          border-radius: 10px;
          border-bottom-left-radius: 0;

          background-color: #e4e8ed;
          text-align: left;
        }
        p{
          margin-top: 8px;
          text-align: left;
          font-size: 12px;
          color: #8b95a1;
        }

      }
      .bubble-left{

        div{

          line-height: 1.86;
          color: #2d3740;
          width: 743px;
          padding: 15px 23px;
          border-radius: 10px;
          border-bottom-right-radius: 0;
          text-align: left;
          background-color: rgba(163, 224, 225,0.5);
        }

        p{
          margin-top: 8px;
          text-align: right;
          font-size: 12px;
          color: #8b95a1;
        }


      }
      .bubble-bi{

        white-space: pre-wrap;
        div{
          background-color: #e4e8ed;

          line-height: 1.86;
          color: #2d3740;
          width: 743px;
          padding: 15px 23px;
          border-radius: 10px;
          border-bottom-left-radius: 0;
          text-align: left;
        }

        p{
          margin-top: 8px;
          text-align: left;
          font-size: 12px;
          color: #8b95a1;
        }


      }



    }
  }

  .attach-icon /deep/ {
    width: 14px;
    height: 14px;
    path {
      fill: #2d3640;
    }
  }


  .delete-icon /deep/ {
    cursor: pointer;
    width: 14px;
    height: 14px;
    path {
      fill: #8b95a1;
    }
  }

  .delete-uploaded-file-img /deep/ {
    width: 18px;
    height: 24px;

    path {
      fill: #5e6b79;
    }
  }

  .step1-card-item {
    width: 40.5%;
    display: flex;
    align-items: center;
    padding: 28px 25px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 33.5px;
    margin-right: 34px;
    border-radius: 2px;
    border: solid 1px rgba(45, 54, 64, 0.2);
    cursor: pointer;
  }
  .continue-button {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #ffffff;
    padding: 17px 0;
    border-radius: 2px;
    cursor: pointer;
    width: 220px;
  }

  .progress-bar-container {
    height: 15px;
    border-radius: 7.5px;
    background-color: #eaedf2;
    margin-bottom: 50px;
  }

  .progress-bar-inner {
    height: 15px;
    border-radius: 7.5px;
    background-color: #67cc64;
  }

  .text-area-description {
    margin-top: 35px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #5e6b79;
  }
  .add-attachment-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8b95a1;
    cursor: pointer;
    margin-left: 5px;
    &:hover{
      color: #2d3740;
    }
  }
  .attached-file-names {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #00a575;
    text-decoration: underline;
  }

  .ticket-submit-description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #5e6b79;
    margin-bottom: 50px;
    span{
      font-weight: bold;
    }
  }

  .ticket-summary-header {
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
    margin-bottom: 20px;
  }

  .ticket-summary-description {
    white-space: pre-line;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #8b95a1;
    padding: 25px;
    min-height: 200px;
  }

  .content-container {
    width: 843px;
    display: flex;
    flex-direction: column;
  }
  .percent-0 {
    width: 0;
    transition: all 0.4s;
  }
  .percent-33 {
    transition: all 0.4s;

    width: 33%;
  }
  .percent-66 {
    width: 66%;
    transition: all 0.4s;
  }
  .percent-100 {
    width: 100%;
    transition: all 0.4s;
  }
  .margin-right-57 {
    //margin-right: 57px;
  }
  .unselected-step1-data {
    border-radius: 5px;
    border: solid 1px #bfc8d2;
    background-color: #ffffff;
    color: #8b95a1;
  }
  .selected-step1-data {
    border-radius: 5px;
    border: solid 1px #67cc64;
    background-color: rgba(103, 204, 100, 0.1);
    color: #2d3640;
  }
  .unselected-continue-button {
    background-color: #bfc8d2;
  }
  .selected-continue-button {
    background-color: #00a575;
  }
  .help-problem-detail {

    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #2d3640;
    padding-top: 25px;
    padding-left: 25px;
    border-radius: 2px;
    background-color: #ffffff;
    min-height: 200px;
    resize: none;
    margin-bottom: 15px;
    width: 97%;
  }
  .help-problem-detail::placeholder {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8b95a1;
  }
  .empty-text-area {
    border: solid 1px rgba(75, 79, 82, 0.2);
  }
  .filled-text-area {
    border: solid 1px #00a575;
  }

  .selected-category-item {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2d3640;
    padding-right: 47px;
  }
  .category-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #8b95a1;
    padding-right: 50px;
  }

  .selected-category-row {
    border-color: #fd4056;
    border-style: solid;
    border-width: 0;
    border-right-width: 3px;
  }
  .ticket-subject-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: pre;
    color: #2d3640;
  }
  .disabled-text {
    color: #8b95a1 !important;
  }
  .answered-text {
    color: #00a575 !important;
  }
  .date-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5e6b79;
  }
  .chat-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #2d3740;
  }
  .chat-text-detail {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8b95a1;
  }
  .ticket-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-right: 20px;
    height: 80px;
    cursor: pointer;
  }

  ///////////

  .menu-list {
    line-height: 1.25;
  }

  ul {
    list-style: none;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  li:hover {
    display: list-item;
    text-align: -webkit-match-parent;
    color: #2d3640;
  }

  .menu-list li {
    cursor: pointer;
    border-radius: 2px;
    color: #4a4a4a;
    display: block;
    padding: 0.5em 0.75em;
    z-index: 99999;
  }

  .menu-list li:hover {
    border-radius: 2px;
    color: #2d3640;
    display: block;
    padding: 0.5em 0.75em;
    text-decoration: none;
  }

  .nav-items {
    padding-left: 20px;
  }

  /*ticket question*/
  .ticket-question-body {
    margin-top: 40px;
    border: 1px solid rgb(234, 237, 242);
    padding: 20px;
  }

  .ticket-question-radio-div {
    margin-bottom: 10px;
    color: #2d3640;
    display: flex;
  }

  .categories-label {
    font-size: 15px;
    color: #2d3640;
    margin-left: 8px;
    display: unset;
    cursor: pointer;
  }

  label {
    margin-bottom: 0;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + .radio-label {
    margin-top: 1px;
    height: 26px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0;
    cursor: pointer;
    margin-left: 10px;
    background: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-unselected.svg)
    no-repeat;
  }

  input[type="radio"]:checked + .radio-label {
    display: inline-block;
    cursor: pointer;
    margin-top: 1px;
    height: 26px;
    width: 16px;
    margin-left: 10px;
    background-image: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-selected.svg);
  }

  .ticket-question-categories {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgb(234, 237, 242);
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .ticket-question-header {
    margin-bottom: 24px;
    color: #2d3640;
    font-size: 18px;
    font-weight: 600;
  }

  .need-more-help-button {
    cursor: pointer;
    margin-top: 30px;
    width: 190px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #8b95a1;
    background-color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #2d3640;
    opacity: 0.9;
  }

  .uploadfile_text {
    float: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  .uploadfile_text:hover {
    text-decoration: underline;
  }

  .progress-bar {
    width: 200px;
    margin: 20px 0 0;
  }

  /*loader part*/
  @keyframes loading {
    10% {
      opacity: 0.4;
    }
    2% {
      opacity: 0.1;
    }
  }

  .ticket-loading {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .ticket-loader {
    min-width: 250px;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    background-color: #dfe4ec;
  }

  .technical-problem-container {
  }

  .technical-problem-header {
    margin-top: 15px;
    margin-bottom: 15px;
  }
</style>
